import { PipeSeparator, Box, Link, Typography, FlexBox, Divider } from '@vp/swan'
import { useSwanStyleKeys } from '@vp/ubik-context'
import { SWAN_STYLE_KEY_MAP } from '@vp/swan'
import { PromoBannerProps } from '../types'
export const PromoSlimBanner = (props : PromoBannerProps) => {
  useSwanStyleKeys([
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.carousel,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.popover,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.visible,
    SWAN_STYLE_KEY_MAP.utility,
  ])
  const { promoHeading, promoCode, promoEndDate, backgroundColor, promoFirstCta, promoSecondCta, promoLegalText, promoLegalLink } = props
  const validCTA1 = promoFirstCta?.text && promoFirstCta?.targetUrl
  const validCTA2 = promoSecondCta?.text && promoSecondCta?.targetUrl
  return (

    <Box bgc={backgroundColor || 'standard'} paddingY={4}>
      <FlexBox justifyContent='center' flexDirection='row' alignItems='center' paddingY={3}>
        {promoHeading && <Typography fontSkin='title-subsection'>{promoHeading}</Typography>}
        {promoCode &&
          (
            <>
              <Box paddingX={4}>
                <PipeSeparator />
              </Box>
              <Typography fontSkin='title-subsection'>{promoCode}</Typography>
            </>
          )}

      </FlexBox>
      <FlexBox justifyContent='center' flexDirection='row' alignItems='center' paddingY={3}>
        {validCTA1 && <Link href={promoFirstCta.targetUrl}>{promoFirstCta.text}</Link>}
        {validCTA2 && (
          <>
            <Box paddingX={4}>
              <PipeSeparator />
            </Box>
            <Link href={promoSecondCta.targetUrl}>{promoSecondCta.text}</Link>
          </>)}
      </FlexBox>
      <FlexBox justifyContent='center' flexDirection='row' alignItems='center' paddingY={3}>
        {promoEndDate && <Typography fontSkin='footnote'>{promoEndDate}</Typography>}

        {promoLegalText &&
          <Box paddingX={4}>
            <PipeSeparator />
          </Box>}

        {promoLegalText && <Typography fontSkin='footnote'>{promoLegalText}&nbsp;</Typography>}
        {promoLegalLink && <Link href={promoLegalLink.targetUrl} fontSkin='footnote'>{promoLegalLink.text}</Link>}
      </FlexBox>
      {backgroundColor === 'standard' ? <Divider /> : null}
    </Box>

  )
}
